<template>
    <div>
        <ConfigKey />
    </div>
</template>

<script>
import ConfigKey from "./configKeyView.vue";
export default {
    components: {
        ConfigKey,
    },
    data() {
        return{
            
        }
    },
    created(){
        
    }
};
</script>
